import './style.less';
import React, { FC } from 'react';
import { ariaRole } from 'components/util/aria';
import { IconTopicGray } from 'components/IconSystem';
import Tag from 'components/tag';

interface EmptyTopicProps {
  onClick: () => void;
}

const EmptyTopic:FC<EmptyTopicProps> = ({ onClick }) => (
  <Tag.div className="empty-topic" onClick={onClick} {...ariaRole('button')} aria-label="话题">
    <IconTopicGray />
    <Tag.span className="empty-topic__text">选择功能投票</Tag.span>
    <Tag.span className="empty-topic__triangle"></Tag.span>
  </Tag.div>
);

export default EmptyTopic;
