import React from 'react';
import Tag from 'components/tag';
import './image-preview-container.less';


const ImagePreviewContainer = (props) => {

    if (!props.images || props.images.length === 0) {
        return null;
    }

    return (
        <Tag.div className="image-preview-container">

            <Tag.ul className="image-preview-container__list" scrollX>
                {props.images.map((image, index) => {
                    return (
                        <Tag.li className="image-preview-container__item" key={index}>
                            <Tag.div className="image-preview-container__del" onClick={event => props.onDel(image.id)} />
                            <Tag.div className="image-preview-container__progress" style={{ height: `${100 - Number(image.progress)}%` }}></Tag.div>
                            <Tag.img src={image.src} className="image-preview-container__img" />
                        </Tag.li>
                    );
                })}
            </Tag.ul>

        </Tag.div>
    );

};

export { ImagePreviewContainer };
