import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Tag from 'components/tag';

import dictionary from './dictionary';

import './style.less';

class EmojiKeyboard extends Component {
  constructor(props) {
    super(props);

    this.emojiArr = this.getEmojiArr();
    this.state = {
      index: 0,
      // 是否已经加载了图片
      hasLoadedImg: !props.isFold,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { hasLoadedImg: state.hasLoadedImg || !props.isFold };
  }

  static emojiCount = 100;

  getEmojiArr() {
    const arr = Array.from({ length: EmojiKeyboard.emojiCount }, (v, k) => k);
    const card = Math.ceil(EmojiKeyboard.emojiCount / 26);
    const result = new Array(card);
    arr.forEach((item) => {
      const index = Math.floor(item / 26);
      if (!result[index]) {
        result[index] = [];
      }
      result[index].push(item + 1);
    });
    result.map(item => item.push('del'));
    return result;
  }

  updateIndex(index) {
    this.setState({ index });
  }

  selectEmoji(emojiIndex) {
    const { props } = this;

    const value = `[${dictionary[emojiIndex]}]`;

    props.onSelectEmoji(value);
  }

  delEmoji() {
    const { props } = this;

    props.onDelEmoji();
  }

  renderSlides() {
    const { index, hasLoadedImg } = this.state;

    return (
      <SwipeableViews
        index={index}
        ignoreNativeScroll={true}
        resistance={true}
        onChangeIndex={index => this.updateIndex(index)}
      >
        {hasLoadedImg
          && this.emojiArr.map((card, index) => (
            <Tag.ul key={index} className="expression-container">
              {card.map((item) => {
                if (item === 'del') {
                  return (
                    <Tag.li
                      key="del-expression"
                      className="expression-item"
                      onClick={() => this.delEmoji()}
                    >
                      <Tag.i className="del-expression" />
                    </Tag.li>
                  );
                }
                return (
                  <Tag.li
                    className="expression-item"
                    key={item}
                    onClick={() => this.selectEmoji(item - 1)}
                  >
                    <Tag.i className={`Expression_${item}`} />
                  </Tag.li>
                );
              })}
            </Tag.ul>
          ))}
      </SwipeableViews>
    );
  }

  renderPagination() {
    const { state } = this;
    return (
      <Tag.div className="emoji-slide-pagination">
        {this.emojiArr.map((slide, i) => (
          <Tag.span
            className={classNames('emoji-slide-dot', {
              active: state.index === i,
            })}
            key={i}
          />
        ))}
      </Tag.div>
    );
  }

  render() {
    const { props } = this;
    return (
      <Tag.div className={classNames('emoji-keyboard', { fold: props.isFold })}>
        <Tag.div className="emoji-container">
          {this.renderSlides()}

          {this.renderPagination()}
        </Tag.div>
      </Tag.div>
    );
  }
}

EmojiKeyboard.propTypes = {
  onDelEmoji: PropTypes.func,
  onSelectEmoji: PropTypes.func,
  isFold: PropTypes.bool,
};

EmojiKeyboard.defaultProps = {
  onDelEmoji: () => console.log('未绑定 onDelEmoji'),
  onSelectEmoji: () => console.log('未绑定 onSelectEmoji'),
  isFold: false,
};

export { EmojiKeyboard };
