/* eslint-disable camelcase */
import React, { Component } from 'react';
import { isEmpty, debounce } from 'lodash-es';
import Tag from 'components/tag';
import classNames from 'classnames';
import { Mask } from 'components/mask';
import { ariaRole } from 'components/util/aria';
import { LoadMore } from 'components/load-more';
import { getProductId } from 'components/util';
import { getApiV3RoadmapTopics, getApiV3RoadmapTopicSearch } from 'components/api';
import { TOPICS_TAB } from 'components/constants/app';
import { IconTopicBlue, TcIconClosure, TcIconSearch, TcIconTopicDefault } from 'components/IconSystem';
import PropTypes from 'prop-types';
import './style.less';
import { TOPIC_SCENE, TOPIC_TAB } from 'components/constants/roadmap';

class TopicMobilePost extends Component {
  constructor(props) {
    super(props);
    this.productId = getProductId();
    this.state = {
      inputKeyword: '',
      topicList: [],
      pagination: null,
    };
    this.handleSearch = debounce(this.handleSearch.bind(this), 800, { leading: true });
  }
  componentDidMount() {
    this.getProductTopicsListApi();
  }
  // 加载产品话题
  getProductTopicsListApi(nextPageId) {
    getApiV3RoadmapTopics({
      productId: this.productId,
      nextPageId,
      tab: TOPIC_TAB.POPULAR,
      scene: TOPIC_SCENE.TYPE_PUBLIC_STAT,
    }).then((res) => {
      if (res.status === 0) {
        this.setState({
          topicList: nextPageId ? this.state.topicList.concat(res.data) : res.data,
          pagination: res.pagination,
        });
      }
      if (this.props.topicId) {
        this.props.getTopicDetails({ id: this.props.topicId, title: this.props.topicTitle });
        this.state.topicList.filter((item) => {
          if (item.id == this.props.topicId) this.props.getTopicDetails(item);
        });
      }
    });
  }

  handleChange(e) {
    // 有用户输入切换到搜索。没用户输入使用常规获取
    this.setState({ inputKeyword: e.target.value });
    this.handleSearch(e.target.value);
  }

  handleSearch(keyword) {
    // handleSearch加了防抖，不应该被条件性触发，判断语句需要放进handleSearch里
    if (keyword) {
      getApiV3RoadmapTopicSearch({
        productId: this.productId,
        keyword,
        scene: TOPIC_SCENE.STAT,
      })
        .then((res) => {
          if (res.status === 0) {
            this.setState({
              topicList: res.data,
              pagination: res.pagination,
            });
          }
        });
    } else {
      this.getProductTopicsListApi();
    }
  }

  handleClick(item) {
    this.props.getTopicDetails(item);
    this.props.onToggleFoldTopic();
  }

  loadMorePage() {
    const { pagination, inputKeyword } = this.state;
    // 用户输入不再加载分页
    if (inputKeyword) return;
    this.getProductTopicsListApi(pagination && pagination.next_page_id);
  }

  render() {
    const { state, props } = this;
    return (
      <>
        <Tag.div className="topic-post">
          <Tag.div className={classNames('topic-post__cont', { fold: props.isFold })}>
            <Tag.h3 className="topic-post__title">
              <Tag.span className="topic-post__title--span">添加话题</Tag.span>
              <TcIconClosure className="topic-post__title--svg" onClick={() => props.onToggleFoldTopic()} />
            </Tag.h3>
            <Tag.div className="topic-post__search">
              <TcIconSearch className="topic-post__search--icon" />
              <Tag.input
                className="topic-post__search--input"
                placeholder="搜索投票"
                value={state.inputKeyword}
                onChange={e => this.handleChange(e)}
                // 小程序没有onChange
                onInput={e => this.handleChange(e)}
              />
            </Tag.div>
            <Tag.ul className="topic-post__topicList">
              {state.topicList.map((item, index) => (
                <Tag.li key={index} className="topic-post__list"
                  {...ariaRole('button')} tabIndex="0" onClick={e => this.handleClick(item)}>
                  {item?.is_recommended
                    ? <Tag.div className="topic-post__list--recommend"></Tag.div>
                    : <IconTopicBlue className="topic-post__list--svg" />
                  }
                  <Tag.p className="topic-post__list--p">{item.title}</Tag.p>
                  <Tag.span className="topic-post__list--span">
                    {item.stat?.like_count || 0}阅读 {item.stat?.content_count || 0}讨论
                    {/* {item.stat?.like_count || 0}顶 */}
                  </Tag.span>
                </Tag.li>
              ))}
              {state.pagination?.has_more_pages && <LoadMore onShow={() => this.loadMorePage()} />}
              {isEmpty(state.topicList) && <Tag.p className="topic-post__list--nodata">还没有相关话题</Tag.p>}
            </Tag.ul>
          </Tag.div>
          {!props.isFold && <Mask weakenBack={true} />}
        </Tag.div>
      </>
    );
  }
}
TopicMobilePost.propTypes = {
  isFold: PropTypes.bool,
  topicId: PropTypes.string,
  topicTitle: PropTypes.string,
  productId: PropTypes.number,
  getTopicDetails: PropTypes.func,
  onToggleFoldTopic: PropTypes.func,
};
TopicMobilePost.defaultProps = {
  topicList: [],
  pagination: {},
};
export { TopicMobilePost };
