import React, { FC } from 'react';
import './style.less';
import Tag from 'components/tag';

interface ConfirmDialogProp {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  subtitle?: string;
  confirmText: string;
}

const ConfirmDialog:FC<ConfirmDialogProp> = ({ onConfirm, onCancel, title, subtitle, confirmText }) => (
  <Tag.div className="confirm-dialog" onClick={onCancel}>
    <Tag.div className="confirm-dialog__container">
      <Tag.div className="confirm-dialog__content">
        <Tag.span className="confirm-dialog__title">{title}</Tag.span>
        {subtitle && <Tag.span className="confirm-dialog__subtitle">{subtitle}</Tag.span>}
      </Tag.div>
      <Tag.div className="confirm-dialog__control">
        <Tag.button className="confirm-dialog__btn" onClick={onCancel}>取消</Tag.button>
        <Tag.div className="confirm-dialog__divider"></Tag.div>
        <Tag.button className="confirm-dialog__btn confirm-dialog__btn--confirm" onClick={onConfirm}>{confirmText}</Tag.button>
      </Tag.div>
    </Tag.div>
  </Tag.div>
);

export default ConfirmDialog;
